:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #ed691f;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-light: #f0f0f0;
  --gray-dark: #343a40;
  --primary: #0058a4;
  --secondary: #f14d5d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #f39413;
  --danger: #4d4949;
  --light: #e4e4e4;
  --dark: #120f2d;
  --offWhite: #f5f5f5;
  --bg-pink: rgb(237, 231, 226);
  --bg-light: #ebedec;
  --divider-gray: #e3e3e3;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --course-img-border: #9a9a9a;
}

.bg-secondary {
  background-color: var(--secondary);
}

.bg-warning {
  background-color: var(--yellow);
}
.bg-blue {
  background-color: var(--blue);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-green {
  background-color: var(--success);
}
::-webkit-scrollbar {
  width: 8px;
  margin: 15px;
}
::-webkit-scrollbar-track {
  background-color: var(--light);
  border-radius: 4px;
  /* border: 0.2px solid var(--gray); */
}
::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 4px;
  /* border: 0.2px solid var(--gray); */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--warning);
  border-radius: 4px;
}

@media only screen and (min-width: 320px) and (max-width: 640px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-20 {
  font-size: 20px;
}
.f-24 {
  font-size: 24px;
}
.f-26 {
  font-size: 26px;
}
.f-30 {
  font-size: 30px;
}
.f-32 {
  font-size: 32px;
}
.f-36 {
  font-size: 36px;
}

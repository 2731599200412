.footer-div {
  background: url('../../assets/img/bg-footer.png');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  width: 100%; 
  height: 90vh;
}
.footer-info {
  text-align: justify;
  line-height: normal;
}

.follow a {
  align-content: center;
  background-color: var(--white);
  border-radius: 5px;
  display: grid;
  height: 30px;
  justify-items: center;
  width: 30px;
  &:hover{
    transform: scale(1.5);
    transition: all 0.5s ease-in-out;
  }
}
.x {
  height: 20px;
  background-color: var(--white);
  border-radius: 4px;
}
.divider-logo {
  height: 2px;
  background-color: var(--white);
  width: 100%;
  margin-top: 6%;
}
.m-footer {
  padding: 0% 10%;
  color: var(--white);
  a {
    color: var(--white);
  }
}

.logo-container {
  padding: 30px 125px;
}
.itt-logo {
  height: 100px;
}

.bottom {
  margin-top: 5rem;
  border-top: 1px solid var(--white);
}

.get-txt {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}
.gap {
  gap: 4rem;
}
@media only screen and (min-width: 320px) and (max-width: 640px) {
  .footer-div {
    height: 60vh;
  }
  .itt-logo {
    height: 50px;
  }
  .divider-logo {
    margin-top: 8.5%;
  }
  .logo-container {
    padding: 10px 16px;
  }
  .get-txt {
    font-size: 0.5rem;
    font-weight: 700;
    margin-bottom: 0.1rem;
  }
  .follow a {
    height: 8px;
    width: 8px;
  }
  .m-footer {
    padding: 0% 5%;
  }
  .follow span {
    font-size: 8px;
  }
  .q-links a {
    font-size: 8px;
  }
  .q-links {
    margin-top: -3rem;
  }
  .links-a {
    height: 1rem;
  }
  .x {
    height: 15px;
  }
  .gap {
    gap: 1rem;
  }
  .bottom {
    margin-top: 1rem;
  }
  .mar-0{
    margin: 0;
  }
}


@media only screen and (min-width: 641px) and (max-width: 990px) {
  .footer-div {
    height: 100vh;
  }
  .itt-logo {
    height: 80px;
  }
  .logo-container {
    padding: 10px 10%;
  }
}